<template>
  <div class="container mt-4">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2>Shoe Stock Management</h2>
      <button @click="refreshStock" class="btn btn-primary" :disabled="loading">
        {{ loading ? "Refreshing..." : "Refresh Stock" }}
      </button>
    </div>
    <small v-if="lastUpdateTime" class="text-muted">
      Last updated: {{ formatDate(lastUpdateTime) }}
    </small>
    <div v-if="loading" class="text-center mt-3">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div v-else-if="error" class="alert alert-danger mt-3" role="alert">
      {{ error }}
    </div>
    <div class="mt-4" v-else>
      <ul class="nav nav-tabs">
        <li class="nav-item" v-for="series in shoeSeries" :key="series">
          <a
            class="nav-link"
            :class="{
              active: activeSeries === series,
              'supernova-rise': series === 'Supernova Rise',
              'supernova-prima': series === 'Supernova Prima',
              'supernova-rise-2': series === 'Supernova Rise 2',
            }"
            @click.prevent="activeSeries = series"
            href="#"
          >
            {{ series }}
          </a>
        </li>
      </ul>

      <div
        v-for="series in shoeSeries"
        :key="series"
        v-show="activeSeries === series"
        :class="{
          'supernova-rise-bg': series === 'Supernova Rise',
          'supernova-prima-bg': series === 'Supernova Prima',
          'supernova-rise-2-bg': series === 'Supernova Rise 2',
        }"
      >
        <div class="row">
          <div class="col-md-6">
            <h4>Men's Shoes</h4>
            <StockTable
              :stock="store.stock[series].men"
              :maxStock="maxStockData[series].men"
              :series="series"
              gender="men"
              @update-stock="updateStock"
            />
          </div>
          <div class="col-md-6">
            <h4>Women's Shoes</h4>
            <StockTable
              :stock="store.stock[series].women"
              :maxStock="maxStockData[series].women"
              :series="series"
              gender="women"
              @update-stock="updateStock"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useFormStore } from "../stores/formStore";
import StockTable from "./StockTable.vue";

export default {
  name: "StockManagement",
  components: { StockTable },
  setup() {
    const store = useFormStore();
    const loading = ref(true);
    const error = ref(null);
    const shoeSeries = ref([
      "Supernova Rise 2",
      "Supernova Rise",
      "Supernova Prima"
    ]);
    const activeSeries = ref("Supernova Rise 2");
    const lastUpdateTime = ref(null);

    const fetchStock = async () => {
      try {
        loading.value = true;
        for (const series of shoeSeries.value) {
          await store.fetchStock(series, "men");
          await store.fetchStock(series, "women");
        }
        // Set initial lastUpdateTime after fetching stock
        lastUpdateTime.value = new Date();
      } catch (err) {
        error.value = "Failed to load stock data";
        console.error(err);
      } finally {
        loading.value = false;
      }
    };

    const maxStockData = {
      "Supernova Rise 2": {
        men: {
          6: 2,
          6.5: 1,
          7: 2,
          7.5: 3,
          8: 3,
          8.5: 3,
          9: 4,
          9.5: 3,
          10: 3,
          10.5: 2,
          11: 2,
          11.5: 1,
        },
        women: {
          3.5: 1,
          4: 2,
          4.5: 2,
          5: 3,
          5.5: 3,
          6: 3,
          6.5: 3,
          7: 2,
          7.5: 1,
        },
      },
      "Supernova Rise": {
        men: {
          6: 3,
          6.5: 3,
          7: 5,
          7.5: 7,
          8: 7,
          8.5: 9,
          9: 13,
          9.5: 7,
          10: 8,
          10.5: 2,
          11: 6,
          11.5: 2,
        },
        women: {
          3.5: 1,
          4: 8,
          4.5: 9,
          5: 16,
          5.5: 16,
          6: 15,
          6.5: 14,
          7: 12,
          7.5: 1,
        },
      },
      "Supernova Prima": {
        men: {
          6: 2,
          6.5: 2,
          7: 2,
          7.5: 2,
          8: 2,
          8.5: 2,
          9: 2,
          9.5: 2,
          10: 2,
          10.5: 2,
          11: 2,
          11.5: 2,
        },
        women: {
          4: 2,
          4.5: 2,
          5: 2,
          5.5: 2,
          6: 2,
          6.5: 2,
          7: 2,
          7.5: 2,
        },
      },
    };

    const updateStock = async (series, gender, size, quantity) => {
      try {
        await store.updateStock(series, gender, size, quantity);
        // Update lastUpdateTime after successful stock update
        lastUpdateTime.value = new Date();
      } catch (err) {
        error.value = "Failed to update stock";
        console.error(err);
      }
    };

    const refreshStock = () => {
      fetchStock();
    };

    const formatDate = (date) => {
      return date.toLocaleString();
    };

    onMounted(fetchStock);

    return {
      store,
      loading,
      error,
      updateStock,
      shoeSeries,
      activeSeries,
      maxStockData,
      lastUpdateTime,
      formatDate,
      refreshStock,
    };
  },
};
</script>

<style scoped>
h2 {
  font-size: 2em;
  margin-bottom: 0;
}
small {
  font-size: 1em;
}
.container {
  background-color: rgb(255, 255, 255);
  color: black;
  padding: 2em;
  border-radius: 20px;
}
.nav-tabs .nav-link {
  cursor: pointer;
}
.nav-tabs .nav-link.active {
  font-weight: bold;
}
.nav-tabs .nav-link.active.supernova-rise {
  background-color: #e6f7ff;
  border-bottom-color: #e6f7ff;
}
.nav-tabs .nav-link.active.supernova-rise-2 {
  background-color: #fb9fcb;
  border-bottom-color: #fb9fcb;
}
.nav-tabs .nav-link.active.supernova-prima {
  background-color: #fff1e6;
  border-bottom-color: #fff1e6;
}
.supernova-rise-bg {
  background-color: #e6f7ff;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
.supernova-prima-bg {
  background-color: #fff1e6;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
.supernova-rise-2-bg {
  background-color: #fb9fcb;
  padding: 20px;
  border-radius: 0 0 10px 10px;
}
</style>
