<!-- TrialPeriodModal.vue -->
<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2 class="modal-title">
        <span class="kanit-regular">เช็คช่วงเวลาการยืม</span> | See the trial
        period
      </h2>
      <p class="kanit-regular text-center">
        *หากวันคืนสินค้าตรงกับวันอาทิตย์หรือวันหยุดนักขัตฤกษ์
        สามารถเลื่อนวันส่งคืนเป็นวันถัดไปได้
      </p>
      <p class="text-center">
        *If the return date falls on a Sunday or a public holiday, the return
        can be made on the next business day.
      </p>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th class="kanit-regular">วันที่ลงทะเบียน</th>
              <th class="kanit-regular">คืนภายในวันที่</th>
              <th>Register Date</th>
              <th>Return Date by</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(period, index) in trialPeriods" :key="index">
              <td class="kanit-regular">{{ period.registerDateThai }}</td>
              <td class="kanit-regular">{{ period.returnDateThai }}</td>
              <td>{{ period.registerDateEng }}</td>
              <td>{{ period.returnDateEng }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <button @click="closeModal" class="close-button w-75">Close</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TrialPeriodModal",
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      trialPeriods: [
        {
          registerDateThai: "3 ธ.ค. 67",
          returnDateThai: "13 ธ.ค. 67",
          registerDateEng: "3-Dec-2024",
          returnDateEng: "13-Dec-2024",
        },
        {
          registerDateThai: "4 ธ.ค. 67",
          returnDateThai: "14 ธ.ค. 67",
          registerDateEng: "4-Dec-2024",
          returnDateEng: "14-Dec-2024",
        },
        {
          registerDateThai: "5 ธ.ค. 67",
          returnDateThai: "15 ธ.ค. 67",
          registerDateEng: "5-Dec-2024",
          returnDateEng: "15-Dec-2024",
        },
        {
          registerDateThai: "6 ธ.ค. 67",
          returnDateThai: "16 ธ.ค. 67",
          registerDateEng: "6-Dec-2024",
          returnDateEng: "16-Dec-2024",
        },
        {
          registerDateThai: "7 ธ.ค. 67",
          returnDateThai: "17 ธ.ค. 67",
          registerDateEng: "7-Dec-2024",
          returnDateEng: "17-Dec-2024",
        },
        {
          registerDateThai: "8 ธ.ค. 67",
          returnDateThai: "18 ธ.ค. 67",
          registerDateEng: "8-Dec-2024",
          returnDateEng: "18-Dec-2024",
        },
        {
          registerDateThai: "9 ธ.ค. 67",
          returnDateThai: "19 ธ.ค. 67",
          registerDateEng: "9-Dec-2024",
          returnDateEng: "19-Dec-2024",
        },
        {
          registerDateThai: "10 ธ.ค. 67",
          returnDateThai: "20 ธ.ค. 67",
          registerDateEng: "10-Dec-2024",
          returnDateEng: "20-Dec-2024",
        },
        {
          registerDateThai: "11 ธ.ค. 67",
          returnDateThai: "21 ธ.ค. 67",
          registerDateEng: "11-Dec-2024",
          returnDateEng: "21-Dec-2024",
        },
        {
          registerDateThai: "12 ธ.ค. 67",
          returnDateThai: "22 ธ.ค. 67",
          registerDateEng: "12-Dec-2024",
          returnDateEng: "22-Dec-2024",
        },
        {
          registerDateThai: "13 ธ.ค. 67",
          returnDateThai: "23 ธ.ค. 67",
          registerDateEng: "13-Dec-2024",
          returnDateEng: "23-Dec-2024",
        },
        {
          registerDateThai: "14 ธ.ค. 67",
          returnDateThai: "24 ธ.ค. 67",
          registerDateEng: "14-Dec-2024",
          returnDateEng: "24-Dec-2024",
        },
        {
          registerDateThai: "15 ธ.ค. 67",
          returnDateThai: "25 ธ.ค. 67",
          registerDateEng: "15-Dec-2024",
          returnDateEng: "25-Dec-2024",
        },
        {
          registerDateThai: "16 ธ.ค. 67",
          returnDateThai: "26 ธ.ค. 67",
          registerDateEng: "16-Dec-2024",
          returnDateEng: "26-Dec-2024",
        },
        {
          registerDateThai: "17 ธ.ค. 67",
          returnDateThai: "27 ธ.ค. 67",
          registerDateEng: "17-Dec-2024",
          returnDateEng: "27-Dec-2024",
        },
        {
          registerDateThai: "18 ธ.ค. 67",
          returnDateThai: "28 ธ.ค. 67",
          registerDateEng: "18-Dec-2024",
          returnDateEng: "28-Dec-2024",
        },
        {
          registerDateThai: "19 ธ.ค. 67",
          returnDateThai: "29 ธ.ค. 67",
          registerDateEng: "19-Dec-2024",
          returnDateEng: "29-Dec-2024",
        },
        {
          registerDateThai: "20 ธ.ค. 67",
          returnDateThai: "30 ธ.ค. 67",
          registerDateEng: "20-Dec-2024",
          returnDateEng: "30-Dec-2024",
        },
        {
          registerDateThai: "21 ธ.ค. 67",
          returnDateThai: "31 ธ.ค. 67",
          registerDateEng: "21-Dec-2024",
          returnDateEng: "31-Dec-2024",
        },
        {
          registerDateThai: "22 ธ.ค. 67",
          returnDateThai: "1 ม.ค. 68",
          registerDateEng: "22-Dec-2024",
          returnDateEng: "1-Jan-2025",
        },
        {
          registerDateThai: "23 ธ.ค. 67",
          returnDateThai: "2 ม.ค. 68",
          registerDateEng: "23-Dec-2024",
          returnDateEng: "2-Jan-2025",
        },
        {
          registerDateThai: "24 ธ.ค. 67",
          returnDateThai: "3 ม.ค. 68",
          registerDateEng: "24-Dec-2024",
          returnDateEng: "3-Jan-2025",
        },
        {
          registerDateThai: "25 ธ.ค. 67",
          returnDateThai: "4 ม.ค. 68",
          registerDateEng: "25-Dec-2024",
          returnDateEng: "4-Jan-2025",
        },
        {
          registerDateThai: "26 ธ.ค. 67",
          returnDateThai: "5 ม.ค. 68",
          registerDateEng: "26-Dec-2024",
          returnDateEng: "5-Jan-2025",
        },
        {
          registerDateThai: "27 ธ.ค. 67",
          returnDateThai: "6 ม.ค. 68",
          registerDateEng: "27-Dec-2024",
          returnDateEng: "6-Jan-2025",
        },
        {
          registerDateThai: "28 ธ.ค. 67",
          returnDateThai: "7 ม.ค. 68",
          registerDateEng: "28-Dec-2024",
          returnDateEng: "7-Jan-2025",
        },
        {
          registerDateThai: "29 ธ.ค. 67",
          returnDateThai: "8 ม.ค. 68",
          registerDateEng: "29-Dec-2024",
          returnDateEng: "8-Jan-2025",
        },
        {
          registerDateThai: "30 ธ.ค. 67",
          returnDateThai: "9 ม.ค. 68",
          registerDateEng: "30-Dec-2024",
          returnDateEng: "9-Jan-2025",
        },
        {
          registerDateThai: "31 ธ.ค. 67",
          returnDateThai: "10 ม.ค. 68",
          registerDateEng: "31-Dec-2024",
          returnDateEng: "10-Jan-2025",
        },
        {
          registerDateThai: "1 ม.ค. 68",
          returnDateThai: "11 ม.ค. 68",
          registerDateEng: "1-Jan-2025",
          returnDateEng: "11-Jan-2025",
        },
        {
          registerDateThai: "2 ม.ค. 68",
          returnDateThai: "12 ม.ค. 68",
          registerDateEng: "2-Jan-2025",
          returnDateEng: "12-Jan-2025",
        },
        {
          registerDateThai: "3 ม.ค. 68",
          returnDateThai: "13 ม.ค. 68",
          registerDateEng: "3-Jan-2025",
          returnDateEng: "13-Jan-2025",
        },
        {
          registerDateThai: "4 ม.ค. 68",
          returnDateThai: "14 ม.ค. 68",
          registerDateEng: "4-Jan-2025",
          returnDateEng: "14-Jan-2025",
        },
        {
          registerDateThai: "5 ม.ค. 68",
          returnDateThai: "15 ม.ค. 68",
          registerDateEng: "5-Jan-2025",
          returnDateEng: "15-Jan-2025",
        },
        {
          registerDateThai: "6 ม.ค. 68",
          returnDateThai: "16 ม.ค. 68",
          registerDateEng: "6-Jan-2025",
          returnDateEng: "16-Jan-2025",
        },
        {
          registerDateThai: "7 ม.ค. 68",
          returnDateThai: "17 ม.ค. 68",
          registerDateEng: "7-Jan-2025",
          returnDateEng: "17-Jan-2025",
        },
        {
          registerDateThai: "8 ม.ค. 68",
          returnDateThai: "18 ม.ค. 68",
          registerDateEng: "8-Jan-2025",
          returnDateEng: "18-Jan-2025",
        },
        {
          registerDateThai: "9 ม.ค. 68",
          returnDateThai: "19 ม.ค. 68",
          registerDateEng: "9-Jan-2025",
          returnDateEng: "19-Jan-2025",
        },
        {
          registerDateThai: "10 ม.ค. 68",
          returnDateThai: "20 ม.ค. 68",
          registerDateEng: "10-Jan-2025",
          returnDateEng: "20-Jan-2025",
        },
        {
          registerDateThai: "11 ม.ค. 68",
          returnDateThai: "21 ม.ค. 68",
          registerDateEng: "11-Jan-2025",
          returnDateEng: "21-Jan-2025",
        },
        {
          registerDateThai: "12 ม.ค. 68",
          returnDateThai: "22 ม.ค. 68",
          registerDateEng: "12-Jan-2025",
          returnDateEng: "22-Jan-2025",
        },
        {
          registerDateThai: "13 ม.ค. 68",
          returnDateThai: "23 ม.ค. 68",
          registerDateEng: "13-Jan-2025",
          returnDateEng: "23-Jan-2025",
        },
        {
          registerDateThai: "14 ม.ค. 68",
          returnDateThai: "24 ม.ค. 68",
          registerDateEng: "14-Jan-2025",
          returnDateEng: "24-Jan-2025",
        },
        {
          registerDateThai: "15 ม.ค. 68",
          returnDateThai: "25 ม.ค. 68",
          registerDateEng: "15-Jan-2025",
          returnDateEng: "25-Jan-2025",
        },
        {
          registerDateThai: "16 ม.ค. 68",
          returnDateThai: "26 ม.ค. 68",
          registerDateEng: "16-Jan-2025",
          returnDateEng: "26-Jan-2025",
        },
        {
          registerDateThai: "17 ม.ค. 68",
          returnDateThai: "27 ม.ค. 68",
          registerDateEng: "17-Jan-2025",
          returnDateEng: "27-Jan-2025",
        },
        {
          registerDateThai: "18 ม.ค. 68",
          returnDateThai: "28 ม.ค. 68",
          registerDateEng: "18-Jan-2025",
          returnDateEng: "28-Jan-2025",
        },
        {
          registerDateThai: "19 ม.ค. 68",
          returnDateThai: "29 ม.ค. 68",
          registerDateEng: "19-Jan-2025",
          returnDateEng: "29-Jan-2025",
        },
        {
          registerDateThai: "20 ม.ค. 68",
          returnDateThai: "30 ม.ค. 68",
          registerDateEng: "20-Jan-2025",
          returnDateEng: "30-Jan-2025",
        },
        {
          registerDateThai: "21 ม.ค. 68",
          returnDateThai: "31 ม.ค. 68",
          registerDateEng: "21-Jan-2025",
          returnDateEng: "31-Jan-2025",
        },
        {
          registerDateThai: "22 ม.ค. 68",
          returnDateThai: "1 ก.พ. 68",
          registerDateEng: "22-Jan-2025",
          returnDateEng: "1-Feb-2025",
        },
        {
          registerDateThai: "23 ม.ค. 68",
          returnDateThai: "2 ก.พ. 68",
          registerDateEng: "23-Jan-2025",
          returnDateEng: "2-Feb-2025",
        },
        {
          registerDateThai: "24 ม.ค. 68",
          returnDateThai: "3 ก.พ. 68",
          registerDateEng: "24-Jan-2025",
          returnDateEng: "3-Feb-2025",
        },
        {
          registerDateThai: "25 ม.ค. 68",
          returnDateThai: "4 ก.พ. 68",
          registerDateEng: "25-Jan-2025",
          returnDateEng: "4-Feb-2025",
        },
        {
          registerDateThai: "26 ม.ค. 68",
          returnDateThai: "5 ก.พ. 68",
          registerDateEng: "26-Jan-2025",
          returnDateEng: "5-Feb-2025",
        },
        {
          registerDateThai: "27 ม.ค. 68",
          returnDateThai: "6 ก.พ. 68",
          registerDateEng: "27-Jan-2025",
          returnDateEng: "6-Feb-2025",
        },
        {
          registerDateThai: "28 ม.ค. 68",
          returnDateThai: "7 ก.พ. 68",
          registerDateEng: "28-Jan-2025",
          returnDateEng: "7-Feb-2025",
        },
        {
          registerDateThai: "29 ม.ค. 68",
          returnDateThai: "8 ก.พ. 68",
          registerDateEng: "29-Jan-2025",
          returnDateEng: "8-Feb-2025",
        },
        {
          registerDateThai: "30 ม.ค. 68",
          returnDateThai: "9 ก.พ. 68",
          registerDateEng: "30-Jan-2025",
          returnDateEng: "9-Feb-2025",
        },
        {
          registerDateThai: "31 ม.ค. 68",
          returnDateThai: "10 ก.พ. 68",
          registerDateEng: "31-Jan-2025",
          returnDateEng: "10-Feb-2025",
        },
        {
          registerDateThai: "1 ก.พ. 68",
          returnDateThai: "11 ก.พ. 68",
          registerDateEng: "1-Feb-2025",
          returnDateEng: "11-Feb-2025",
        },
        {
          registerDateThai: "2 ก.พ. 68",
          returnDateThai: "12 ก.พ. 68",
          registerDateEng: "2-Feb-2025",
          returnDateEng: "12-Feb-2025",
        },
        {
          registerDateThai: "3 ก.พ. 68",
          returnDateThai: "13 ก.พ. 68",
          registerDateEng: "3-Feb-2025",
          returnDateEng: "13-Feb-2025",
        },
        {
          registerDateThai: "4 ก.พ. 68",
          returnDateThai: "14 ก.พ. 68",
          registerDateEng: "4-Feb-2025",
          returnDateEng: "14-Feb-2025",
        },
        {
          registerDateThai: "5 ก.พ. 68",
          returnDateThai: "15 ก.พ. 68",
          registerDateEng: "5-Feb-2025",
          returnDateEng: "15-Feb-2025",
        },
        {
          registerDateThai: "6 ก.พ. 68",
          returnDateThai: "16 ก.พ. 68",
          registerDateEng: "6-Feb-2025",
          returnDateEng: "16-Feb-2025",
        },
        {
          registerDateThai: "7 ก.พ. 68",
          returnDateThai: "17 ก.พ. 68",
          registerDateEng: "7-Feb-2025",
          returnDateEng: "17-Feb-2025",
        },
        {
          registerDateThai: "8 ก.พ. 68",
          returnDateThai: "18 ก.พ. 68",
          registerDateEng: "8-Feb-2025",
          returnDateEng: "18-Feb-2025",
        },
        {
          registerDateThai: "9 ก.พ. 68",
          returnDateThai: "19 ก.พ. 68",
          registerDateEng: "9-Feb-2025",
          returnDateEng: "19-Feb-2025",
        },
        {
          registerDateThai: "10 ก.พ. 68",
          returnDateThai: "20 ก.พ. 68",
          registerDateEng: "10-Feb-2025",
          returnDateEng: "20-Feb-2025",
        },
        {
          registerDateThai: "11 ก.พ. 68",
          returnDateThai: "21 ก.พ. 68",
          registerDateEng: "11-Feb-2025",
          returnDateEng: "21-Feb-2025",
        },
        {
          registerDateThai: "12 ก.พ. 68",
          returnDateThai: "22 ก.พ. 68",
          registerDateEng: "12-Feb-2025",
          returnDateEng: "22-Feb-2025",
        },
        {
          registerDateThai: "13 ก.พ. 68",
          returnDateThai: "23 ก.พ. 68",
          registerDateEng: "13-Feb-2025",
          returnDateEng: "23-Feb-2025",
        },
        {
          registerDateThai: "14 ก.พ. 68",
          returnDateThai: "24 ก.พ. 68",
          registerDateEng: "14-Feb-2025",
          returnDateEng: "24-Feb-2025",
        },
        {
          registerDateThai: "15 ก.พ. 68",
          returnDateThai: "25 ก.พ. 68",
          registerDateEng: "15-Feb-2025",
          returnDateEng: "25-Feb-2025",
        },
        {
          registerDateThai: "16 ก.พ. 68",
          returnDateThai: "26 ก.พ. 68",
          registerDateEng: "16-Feb-2025",
          returnDateEng: "26-Feb-2025",
        },
        {
          registerDateThai: "17 ก.พ. 68",
          returnDateThai: "27 ก.พ. 68",
          registerDateEng: "17-Feb-2025",
          returnDateEng: "27-Feb-2025",
        },
        {
          registerDateThai: "18 ก.พ. 68",
          returnDateThai: "28 ก.พ. 68",
          registerDateEng: "18-Feb-2025",
          returnDateEng: "28-Feb-2025",
        },
        {
          registerDateThai: "19 ก.พ. 68",
          returnDateThai: "1 มี.ค. 68",
          registerDateEng: "19-Feb-2025",
          returnDateEng: "1-Mar-2025",
        },
        {
          registerDateThai: "20 ก.พ. 68",
          returnDateThai: "2 มี.ค. 68",
          registerDateEng: "20-Feb-2025",
          returnDateEng: "2-Mar-2025",
        },
        {
          registerDateThai: "21 ก.พ. 68",
          returnDateThai: "3 มี.ค. 68",
          registerDateEng: "21-Feb-2025",
          returnDateEng: "3-Mar-2025",
        },
        {
          registerDateThai: "22 ก.พ. 68",
          returnDateThai: "4 มี.ค. 68",
          registerDateEng: "22-Feb-2025",
          returnDateEng: "4-Mar-2025",
        },
        {
          registerDateThai: "23 ก.พ. 68",
          returnDateThai: "5 มี.ค. 68",
          registerDateEng: "23-Feb-2025",
          returnDateEng: "5-Mar-2025",
        },
        {
          registerDateThai: "24 ก.พ. 68",
          returnDateThai: "6 มี.ค. 68",
          registerDateEng: "24-Feb-2025",
          returnDateEng: "6-Mar-2025",
        },
        {
          registerDateThai: "25 ก.พ. 68",
          returnDateThai: "7 มี.ค. 68",
          registerDateEng: "25-Feb-2025",
          returnDateEng: "7-Mar-2025",
        },
        {
          registerDateThai: "26 ก.พ. 68",
          returnDateThai: "8 มี.ค. 68",
          registerDateEng: "26-Feb-2025",
          returnDateEng: "8-Mar-2025",
        },
        {
          registerDateThai: "27 ก.พ. 68",
          returnDateThai: "9 มี.ค. 68",
          registerDateEng: "27-Feb-2025",
          returnDateEng: "9-Mar-2025",
        },
        {
          registerDateThai: "28 ก.พ. 68",
          returnDateThai: "10 มี.ค. 68",
          registerDateEng: "28-Feb-2025",
          returnDateEng: "10-Mar-2025",
        },
        {
          registerDateThai: "1 มี.ค. 68",
          returnDateThai: "11 มี.ค. 68",
          registerDateEng: "1-Mar-2025",
          returnDateEng: "11-Mar-2025",
        },
        {
          registerDateThai: "2 มี.ค. 68",
          returnDateThai: "12 มี.ค. 68",
          registerDateEng: "2-Mar-2025",
          returnDateEng: "12-Mar-2025",
        },
        {
          registerDateThai: "3 มี.ค. 68",
          returnDateThai: "13 มี.ค. 68",
          registerDateEng: "3-Mar-2025",
          returnDateEng: "13-Mar-2025",
        },
        {
          registerDateThai: "4 มี.ค. 68",
          returnDateThai: "14 มี.ค. 68",
          registerDateEng: "4-Mar-2025",
          returnDateEng: "14-Mar-2025",
        },
        {
          registerDateThai: "5 มี.ค. 68",
          returnDateThai: "15 มี.ค. 68",
          registerDateEng: "5-Mar-2025",
          returnDateEng: "15-Mar-2025",
        },
        {
          registerDateThai: "6 มี.ค. 68",
          returnDateThai: "16 มี.ค. 68",
          registerDateEng: "6-Mar-2025",
          returnDateEng: "16-Mar-2025",
        },
        {
          registerDateThai: "7 มี.ค. 68",
          returnDateThai: "17 มี.ค. 68",
          registerDateEng: "7-Mar-2025",
          returnDateEng: "17-Mar-2025",
        },
        {
          registerDateThai: "8 มี.ค. 68",
          returnDateThai: "18 มี.ค. 68",
          registerDateEng: "8-Mar-2025",
          returnDateEng: "18-Mar-2025",
        },
        {
          registerDateThai: "9 มี.ค. 68",
          returnDateThai: "19 มี.ค. 68",
          registerDateEng: "9-Mar-2025",
          returnDateEng: "19-Mar-2025",
        },
        {
          registerDateThai: "10 มี.ค. 68",
          returnDateThai: "20 มี.ค. 68",
          registerDateEng: "10-Mar-2025",
          returnDateEng: "20-Mar-2025",
        },
        {
          registerDateThai: "11 มี.ค. 68",
          returnDateThai: "21 มี.ค. 68",
          registerDateEng: "11-Mar-2025",
          returnDateEng: "21-Mar-2025",
        },
        {
          registerDateThai: "12 มี.ค. 68",
          returnDateThai: "22 มี.ค. 68",
          registerDateEng: "12-Mar-2025",
          returnDateEng: "22-Mar-2025",
        },
        {
          registerDateThai: "13 มี.ค. 68",
          returnDateThai: "23 มี.ค. 68",
          registerDateEng: "13-Mar-2025",
          returnDateEng: "23-Mar-2025",
        },
        {
          registerDateThai: "14 มี.ค. 68",
          returnDateThai: "24 มี.ค. 68",
          registerDateEng: "14-Mar-2025",
          returnDateEng: "24-Mar-2025",
        },
        {
          registerDateThai: "15 มี.ค. 68",
          returnDateThai: "25 มี.ค. 68",
          registerDateEng: "15-Mar-2025",
          returnDateEng: "25-Mar-2025",
        },
        {
          registerDateThai: "16 มี.ค. 68",
          returnDateThai: "26 มี.ค. 68",
          registerDateEng: "16-Mar-2025",
          returnDateEng: "26-Mar-2025",
        },
        {
          registerDateThai: "17 มี.ค. 68",
          returnDateThai: "27 มี.ค. 68",
          registerDateEng: "17-Mar-2025",
          returnDateEng: "27-Mar-2025",
        },
        {
          registerDateThai: "18 มี.ค. 68",
          returnDateThai: "28 มี.ค. 68",
          registerDateEng: "18-Mar-2025",
          returnDateEng: "28-Mar-2025",
        },
        {
          registerDateThai: "19 มี.ค. 68",
          returnDateThai: "29 มี.ค. 68",
          registerDateEng: "19-Mar-2025",
          returnDateEng: "29-Mar-2025",
        },
        {
          registerDateThai: "20 มี.ค. 68",
          returnDateThai: "30 มี.ค. 68",
          registerDateEng: "20-Mar-2025",
          returnDateEng: "30-Mar-2025",
        },
        {
          registerDateThai: "21 มี.ค. 68",
          returnDateThai: "31 มี.ค. 68",
          registerDateEng: "21-Mar-2025",
          returnDateEng: "31-Mar-2025",
        },
        {
          registerDateThai: "22 มี.ค. 68",
          returnDateThai: "1 เม.ย. 68",
          registerDateEng: "22-Mar-2025",
          returnDateEng: "1-Apr-2025",
        },
        {
          registerDateThai: "23 มี.ค. 68",
          returnDateThai: "2 เม.ย. 68",
          registerDateEng: "23-Mar-2025",
          returnDateEng: "2-Apr-2025",
        },
        {
          registerDateThai: "24 มี.ค. 68",
          returnDateThai: "3 เม.ย. 68",
          registerDateEng: "24-Mar-2025",
          returnDateEng: "3-Apr-2025",
        },
        {
          registerDateThai: "25 มี.ค. 68",
          returnDateThai: "4 เม.ย. 68",
          registerDateEng: "25-Mar-2025",
          returnDateEng: "4-Apr-2025",
        },
        {
          registerDateThai: "26 มี.ค. 68",
          returnDateThai: "5 เม.ย. 68",
          registerDateEng: "26-Mar-2025",
          returnDateEng: "5-Apr-2025",
        },
        {
          registerDateThai: "27 มี.ค. 68",
          returnDateThai: "6 เม.ย. 68",
          registerDateEng: "27-Mar-2025",
          returnDateEng: "6-Apr-2025",
        },
        {
          registerDateThai: "28 มี.ค. 68",
          returnDateThai: "7 เม.ย. 68",
          registerDateEng: "28-Mar-2025",
          returnDateEng: "7-Apr-2025",
        },
        {
          registerDateThai: "29 มี.ค. 68",
          returnDateThai: "8 เม.ย. 68",
          registerDateEng: "29-Mar-2025",
          returnDateEng: "8-Apr-2025",
        },
        {
          registerDateThai: "30 มี.ค. 68",
          returnDateThai: "9 เม.ย. 68",
          registerDateEng: "30-Mar-2025",
          returnDateEng: "9-Apr-2025",
        },
        {
          registerDateThai: "31 มี.ค. 68",
          returnDateThai: "10 เม.ย. 68",
          registerDateEng: "31-Mar-2025",
          returnDateEng: "10-Apr-2025",
        },
      ],
    };
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(10, 10, 10);
  border-radius: 5px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
}

.modal-title {
  text-align: center;
  margin: 20px 0;
  font-size: 1.5em;
}

.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 90%;
  margin: 0 auto;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

th {
  background-color: var(--hover-color);
  color: white;
}

.close-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: var(--hover-color);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }
}
</style>
