<template>
  <div>
    <h3 class="kanit-regular">ขั้นตอนที่ 1 : เลือกรองเท้า</h3>
    <h3>Step 1: Select Shoes</h3>

    <div v-if="isLoading" class="loading-spinner">
      <div class="spinner"></div>
      <p>Loading shoe data...</p>
    </div>

    <div v-else>
      <!-- Shoe Series Selection -->
      <div class="mb-3">
        <label class="form-label mb-2"
          ><span class="kanit-regular">รุ่นรองเท้า</span>/ Shoe model</label
        >
        <div class="series-selection">
          <button
            v-for="series in shoeSeries"
            :key="series"
            type="button"
            class="btn btn-series"
            :class="{ active: selectedSeries === series }"
            @click="selectSeries(series)"
          >
            {{ series }}
          </button>
        </div>
      </div>

      <!-- Gender Selection -->
      <div v-if="selectedSeries" class="mb-3">
        <label class="form-label"
          ><span class="kanit-regular">เพศ</span> / Gender</label
        >
        <div class="gender-selection">
          <button
            v-for="gender in genders"
            :key="gender"
            type="button"
            class="btn btn-gender"
            :class="{ active: selectedGender === gender }"
            @click="selectGender(gender)"
          >
            {{ gender }}
          </button>
        </div>
      </div>

      <!-- Shoe Size Selection -->
      <div v-if="selectedGender && selectedSeries" class="mb-3">
        <div class="size-selection-header">
          <label class="form-label">
            <span class="kanit-regular">ขนาดรองเท้า</span> / Shoe size
          </label>
          <p class="size-chart-link" @click="openSizeChart">
            <small
              @click="openSizeChartModal"
              class="itc-franklin-gothic"
              style="font-size: 1em !important"
              >See Size Charts</small
            >
          </p>
        </div>
        <div class="shoe-size-grid">
          <button
            v-for="size in availableSizes"
            :key="size"
            type="button"
            class="shoe-size-btn"
            :class="{
              active: shoeSize === size.toString(),
              disabled: getStock(size) === 0,
            }"
            @click="selectSize(size)"
            :disabled="getStock(size) === 0"
          >
            {{ size }} UK
          </button>
        </div>

        <p class="text-center mt-4" style="font-size: 1em">
          <span class="kanit-regular"
            >ท่านสามารถยืมรองเท้าเป็นเวลา 10 วัน เริ่มนับวันหลังจากวันลงทะเบียน
            และยืมได้เพียง 1 คู่ในช่วงเวลาเดียวกัน</span
          ><br />
          <span>
            You can borrow the shoes for 10 days, starting from the day after
            registration. You can borrow only one pair at a time.
          </span>
        </p>

        <div class="mt-3">
          <p
            class="text-center size-chart-link"
            @click="openTrailPeriodModal"
            style="color: white; font-size: 1em"
          >
            <span class="kanit-regular">เช็คช่วงเวลาการยืม</span>
            | See the trial period
          </p>
        </div>

        <!-- Add the SizeChartModal component -->
        <SizeChartModal
          :isOpen="isSizeChartModalOpen"
          @close="closeSizeChartModal"
        />

        <TrialPeriodModal
          :isOpen="isTrialPeriodModalOpen"
          @close="closeTrialPeriodModal"
        />

        <div class="invalid-feedback" v-if="v$.shoeSize.$error">
          {{ v$.shoeSize.$errors[0].$message }}
        </div>
      </div>

      <button
        @click="nextStep"
        class="btn btn-primary w-100 mx-auto mt-3 py-2"
        :disabled="!isSelectionValid"
      >
        Next
      </button>

      <p class="text-center mt-3" style="font-size: 1em">
        <span class="kanit-regular"
          >หมายเหตุ รองเท้าที่ได้รับอาจมีสีแตกต่างจากรูปที่แสดง </span
        ><br />
        <span style="font-size: 1.1em">
          Remark: The color of trial shoes that will be sent to you might differ
          from the pictures.
        </span>
      </p>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useFormStore } from "../stores/formStore";
import { computed, onMounted, watch, ref } from "vue";
import SizeChartModal from "./SizeChartModal.vue";
import TrialPeriodModal from "./TrialPeriodModal.vue";

export default {
  name: "ShoeSelection",
  components: {
    SizeChartModal,
    TrialPeriodModal,
  },

  setup() {
    const store = useFormStore();
    const shoeSeries = ["Supernova Rise 2","Supernova Rise", "Supernova Prima"];
    const genders = ["men", "women"];
    const isLoading = ref(true);

    const selectedSeries = computed({
      get: () => store.formData.shoeSeries,
      set: (value) => store.updateFormField("shoeSeries", value),
    });
    const selectedGender = computed({
      get: () => store.formData.gender,
      set: (value) => store.updateFormField("gender", value),
    });
    const shoeSize = computed({
      get: () => store.formData.shoeSize,
      set: (value) => store.updateFormField("shoeSize", value),
    });

    const availableSizes = computed(() => {
      if (selectedSeries.value && selectedGender.value) {
        return store
          .getAvailableSizes(selectedSeries.value, selectedGender.value)
          .map((size) => parseFloat(size))
          .sort((a, b) => a - b)
          .map((size) => size.toString());
      }
      return [];
    });

    const rules = {
      shoeSize: { required },
    };

    const v$ = useVuelidate(rules, { shoeSize });

    const selectSeries = (series) => {
      selectedSeries.value = series;
      selectedGender.value = "";
      shoeSize.value = "";
    };

    const selectGender = (gender) => {
      selectedGender.value = gender;
      shoeSize.value = "";
    };

    const selectSize = (size) => {
      if (getStock(size) > 0) {
        shoeSize.value = size.toString();
      }
    };

    const getStock = (size) => {
      return store.getStock(selectedSeries.value, selectedGender.value, size);
    };

    const isSelectionValid = computed(() => {
      return selectedSeries.value && selectedGender.value && shoeSize.value;
    });

    const nextStep = async () => {
      const isValid = await v$.value.$validate();
      if (isValid && isSelectionValid.value) {
        store.nextStep();
      }
    };

    const isSizeChartModalOpen = ref(false);

    const openSizeChartModal = () => {
      isSizeChartModalOpen.value = true;
    };

    const closeSizeChartModal = () => {
      isSizeChartModalOpen.value = false;
    };

    const openSizeChart = () => {
      isSizeChartModalOpen.value = true;
    };

    const isTrialPeriodModalOpen = ref(false);

    const closeTrialPeriodModal = () => {
      isTrialPeriodModalOpen.value = false;
    };

    const openTrailPeriodModal = () => {
      isTrialPeriodModalOpen.value = true;
    };

    const preloadData = async () => {
      isLoading.value = true;
      try {
        await store.fetchStock();
        // Pre-fetch data for all series and genders
        for (const series of shoeSeries) {
          for (const gender of genders) {
            await store.fetchStock(series, gender);
          }
        }
      } catch (error) {
        console.error("Error preloading data:", error);
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      preloadData();
    });

    watch([selectedSeries, selectedGender], async () => {
      if (selectedSeries.value && selectedGender.value) {
        isLoading.value = true;
        await store.fetchStock(selectedSeries.value, selectedGender.value);
        isLoading.value = false;
      }
    });

    return {
      shoeSeries,
      genders,
      selectedSeries,
      selectedGender,
      shoeSize,
      availableSizes,
      v$,
      selectSeries,
      selectGender,
      selectSize,
      getStock,
      isSelectionValid,
      nextStep,
      isLoading,
      isSizeChartModalOpen,
      openSizeChartModal,
      closeSizeChartModal,
      openSizeChart,
      isTrialPeriodModalOpen,
      openTrailPeriodModal,
      closeTrialPeriodModal,
    };
  },
};
</script>

<style scoped>
.series-selection,
.gender-selection {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.btn-series,
.btn-gender {
  font-size: 1.2em;
  flex: 1;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: #000;
  text-transform: capitalize;
  transition: all 0.3s ease;
}

.btn-series:hover,
.btn-series.active,
.btn-gender:hover,
.btn-gender.active {
  background-color: var(--hover-color);
  color: #fff;
}

.shoe-size-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.shoe-size-btn {
  width: 100%;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  color: var(--hover-color);
  transition: all 0.3s ease;
}

.shoe-size-btn:hover:not(.disabled),
.shoe-size-btn.active {
  background-color: var(--hover-color);
  color: #fff;
}

.shoe-size-btn.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loading-spinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid var(--hover-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.size-selection-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.form-label {
  margin-bottom: 0; /* Remove default margin */
}

.size-chart-link {
  margin: 0;
  cursor: pointer;
  /* color: var(--hover-color); Use your theme color */
  text-decoration: underline;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
