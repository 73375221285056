<template>
  <div class="home-page">
    <div class="container-fluid px-0">
      <div class="row no-gutters justify-content-center">
        <div class="col-12 text-center">
          <div class="logo-container mx-auto mt-3">
            <img
              src="@/assets/logo-supernova.svg"
              alt="Supernova Rise Shoes"
              class="logo-image"
            />
          </div>
          <div class="full-width-image-container mt-4">
            <img
              src="@/assets/adidas-supernova-rise2-highlight-dt.jpg"
              class="full-width-image"
              alt="Adidas Supernova Rise"
            />
          </div>

          <div class="container-fluid px-3 mt-2">
            <h1 class="m-2">
              <span class="d-none d-md-inline main-heading-th">
                สัมผัสความสบายขั้นสุด จากรองเท้าวิ่ง
                <span class="itc-franklin-gothic-dmcp">SUPERNOVA</span>
              </span>
              <span class="d-md-none main-heading-th">
                สัมผัสความสบายขั้นสุด<br />จากรองเท้าวิ่ง
                <span class="itc-franklin-gothic">SUPERNOVA</span>
              </span>
            </h1>
            <p class="main-heading mt-2 m-2">Experience super comfort from SUPERNOVA</p>
            <p class="main-detail mb-3">ทดลองใส่ฟรี ด้วยขั้นตอนง่าย ๆ</p>
            <p class="itc-franklin-gothic mb-3" style="font-size: 1.4em">
              Free trial with simple steps!
            </p>

            <div class="icon-row-container mb-4">
              <div class="row justify-content-center icon-row g-3">
                <div class="col-3 col-md-3 img-icon-wrapper">
                  <div class="img-icon">
                    <img
                      src="@/assets/Flow Adidas_Icon-01.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p class="icon-text kanit-regular">1.ลงทะเบียน</p>
                  <p class="icon-text en-font">Register</p>
                </div>

                <div class="col-3 col-md-3 img-icon-wrapper">
                  <div class="img-icon">
                    <img
                      src="@/assets/Flow Adidas_Icon-03.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p class="icon-text kanit-regular">2.ได้รับ<br />รองเท้า</p>
                  <p class="icon-text en-font">Recieve the shoes</p>
                </div>

                <div class="col-3 col-md-3 img-icon-wrapper">
                  <div class="img-icon">
                    <img
                      src="@/assets/Flow Adidas_Icon-04.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p class="icon-text kanit-regular">3.ทดลองใส่</p>
                  <p class="icon-text en-font">Trial</p>
                </div>

                <div class="col-3 col-md-3 img-icon-wrapper">
                  <div class="img-icon">
                    <img
                      src="@/assets/Flow Adidas_Icon-05.png"
                      class="img-fluid"
                      alt=""
                    />
                  </div>
                  <p class="icon-text kanit-regular">4.ส่งรองเท้าคืน</p>
                  <p class="icon-text en-font">Return the shoes</p>
                </div>
              </div>
            </div>

            <div class="button-container">
              <router-link to="/terms" class="btn btn-primary btn-lg get-started-btn">
                NEXT
              </router-link>
            </div>

            <p class="mb-2 main-detail">
              *เงื่อนไขเป็นไปตามที่บริษัทฯ กำหนด<br />
              <span class="en-font itc-franklin-gothic">
                *Terms and Conditions apply</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
.home-page {
  overflow-x: hidden;
}

.container-fluid {
  max-width: 100%;
  overflow-x: hidden;
}

.row {
  margin-left: 0;
  margin-right: 0;
}

/* Logo */
.logo-container {
  max-width: 200px;
  width: 50%;
  margin: 1rem auto;
}

/* Full-width image */
.full-width-image-container {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.full-width-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Typography */
.main-heading,
.main-heading-th,
.main-detail {
  color: var(--text-color-light);
}

.main-heading {
  font-size: 1.2rem;
  font-family: "ITC Franklin Gothic", sans-serif;
}

.main-heading-th {
  font-size: 1.5rem;
  font-family: "Kanit", sans-serif;
}

.main-detail {
  font-size: 0.9rem;
  font-family: "Kanit", sans-serif;
  margin-bottom: 0;
}

/* Icon row */
.icon-row-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.icon-row {
  width: 100%;
  max-width: 500px;
}

.img-icon-wrapper {
  padding-left: 5px;
  padding-right: 5px;
}

.img-icon {
  background-color: white;
  border-radius: 10%;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon-text {
  margin-top: 0.7rem;
  font-size: 1rem;
  text-align: center;
  line-height: 1.2;
  margin-bottom: 0;
}

.en-font {
  font-size: 1.1em !important;
}

/* Button */
.button-container {
  margin: 1.5rem 0;
}

.get-started-btn {
  width: 90%;
  max-width: 400px;
  padding: 0.9rem 1rem;
  font-size: 1.2rem;
}

/* Media Queries */
@media (min-width: 768px) {
  .logo-container {
    max-width: 260px;
    width: 60%;
  }

  .full-width-image-container {
    height: 300px;
  }

  .main-heading,
  .main-heading-th {
    font-size: 2rem;
  }

  .main-detail {
    font-size: 1.2rem;
  }

  .get-started-btn {
    width: 100%;
    max-width: 500px;
    padding: 0.75rem 1.5rem;
    font-size: 1.25rem;
  }

  .icon-text {
    font-size: 0.8rem;
  }

  .icon-text br {
    display: none;
  }
}

@media (min-width: 1024px) {
  .full-width-image-container {
    height: 350px;
  }

  .main-heading,
  .main-heading-th {
    font-size: 2.5rem;
  }

  .img-icon {
    padding: 1rem;
  }

  .icon-text {
    font-size: 0.9rem;
  }
}

@media (min-width: 1440px) {
  .full-width-image-container {
    height: 400px;
  }
}
</style>
