<template>
  <FormWrapper>
    <div class="container mt-2">
      <div v-if="!store.submissionComplete">
        <h2 class="mb-4">Registration Form</h2>
        <div class="progress mb-4">
          <div
            class="progress-bar"
            role="progressbar"
            :style="{ width: store.progressWidth + '%' }"
            :aria-valuenow="store.currentStep"
            aria-valuemin="1"
            aria-valuemax="3"
            style="font-size: 1.2em; height: 20px"
          >
            Step {{ store.currentStep }} of 3
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 mx-auto my-4 p-4">
            <transition name="fade" mode="out-in">
              <img
                :key="currentImage"
                :src="currentImage"
                width="100%"
                alt="Shoes"
              />
            </transition>
          </div>

          <div class="col-md-6 form-container">
            <div class="form-content position-relative">
              <ShoeSelection v-if="store.currentStep === 1" ref="step1" />
              <PersonalInfo v-if="store.currentStep === 2" ref="step2" />
              <OtpVerification v-if="store.currentStep === 3" ref="step3" />
            </div>
          </div>
        </div>
      </div>

      <ThankYou v-else @go-back-to-form="store.resetForm" />
    </div>
  </FormWrapper>
</template>

<script>
import { watch, onMounted, computed } from "vue";
import { useFormStore } from "../stores/formStore";
import FormWrapper from "./FormWrapper.vue";
import ShoeSelection from "./ShoeSelection.vue";
import PersonalInfo from "./PersonalInfo.vue";
import OtpVerification from "./OtpVerification.vue";
import ThankYou from "./ThankYou.vue";

import primaMen from "@/assets/prima-men.png";
import primaWoman from "@/assets/prima-woman.png";
import riseMen from "@/assets/rise-men.png";
import riseWoman from "@/assets/rise-woman.png";
import riseWoman2 from "@/assets/rise-woman2.png";
import riseMen2 from "@/assets/rise-men2.png";
import defaultImage from "@/assets/adidas-supernova-rise-highlight.jpg";

export default {
  name: "MainForm",
  components: {
    ShoeSelection,
    PersonalInfo,
    OtpVerification,
    ThankYou,
    FormWrapper,
  },
  setup() {
    const store = useFormStore();

    const currentImage = computed(() => {
      const { shoeSeries, gender } = store.formData;
      if (shoeSeries === "Supernova Prima") {
        return gender === "women" ? primaWoman : primaMen;
      } else if (shoeSeries === "Supernova Rise") {
        return gender === "women" ? riseWoman : riseMen;
      } else if (shoeSeries === "Supernova Rise 2") {
        return gender === "women" ? riseWoman2 : riseMen2;
      }
      return defaultImage; // Default image
    });

    watch(
      () => store.currentStep,
      () => {
        scrollToTop();
      }
    );

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    onMounted(() => {
      store.resetStore();
      scrollToTop();
    });

    return {
      store,
      currentImage,
    };
  },
};
</script>

<style scoped>
.form-container {
  position: relative;
}

.form-content {
  padding: 20px;
  border-radius: 8px;
}

h2 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
