<template>
  <div class="floating-label-input">
    <input
      :id="id"
      :type="type"
      :value="modelValue"
      @input="onInput"
      @blur="onBlur"
      @focus="onFocus"
      :disabled="disabled"
      :maxlength="maxlength"
      :class="{ 'has-value': modelValue, 'is-invalid': showError }"
      :readonly="readonly"
      required
      data-lpignore="true"
    />
    <label :for="id">{{ label }}</label>
    <div v-if="showError" class="invalid-feedback">
      {{ errorMessage || validationMessage || `กรุณาตรวจสอบความถูกต้องของ ${label}` }}
    </div>
  </div>
</template>

<script>
export default {
  name: "FloatingLabelInput",
  props: {
    id: String,
    label: String,
    modelValue: String,
    type: {
      type: String,
      default: "text",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: Boolean,
    maxlength: String,
    error: Boolean,
    errorMessage: String,
  },
  emits: ["update:modelValue", "blur"],
  data() {
    return {
      touched: false,
      focused: false,
      validationMessage: "",
    };
  },
  computed: {
    showError() {
      return (
        (this.error || this.validationMessage || this.isEmpty) &&
        this.touched &&
        !this.focused
      );
    },
    isEmpty() {
      return this.touched && !this.modelValue && !this.focused;
    },
  },
  methods: {
    onInput(event) {
      const value = event.target.value;
      this.$emit("update:modelValue", value);

      if (this.type === "email") {
        this.validateEmail(value);
      }
    },
    onBlur(event) {
      this.touched = true;
      this.focused = false;
      this.$emit("blur", event);

      if (this.type === "email") {
        this.validateEmail(this.modelValue);
      }
    },
    onFocus() {
      this.focused = true;
    },
    validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (email && !emailRegex.test(email)) {
        this.validationMessage = "Please enter a valid email address";
      } else {
        this.validationMessage = "";
      }
    },
  },
};
</script>

<style scoped>
.floating-label-input {
  position: relative;
  margin-bottom: 20px;
}

.floating-label-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.floating-label-input label {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 16px;
  color: #999;
  pointer-events: none;
  transition: all 0.3s ease;
}

.floating-label-input input:focus,
.floating-label-input input.has-value {
  border-color: #007bff;
  outline: none;
}

.floating-label-input input:focus + label,
.floating-label-input input.has-value + label {
  top: -10px;
  left: 5px;
  font-size: 12px;
  color: #007bff;
  background-color: white;
  padding: 0 5px;
}

.floating-label-input input.is-invalid {
  border-color: #dc3545;
}

.floating-label-input .invalid-feedback {
  color: #dc3545;
  font-size: 80%;
  margin-top: 0.25rem;
}

input::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
</style>
